/**
 * Stylesheet for Mankiewicz
 *
 * Copyright: Hafenkrone - Agentur für digitale Zeiten GmbH
 */

@import "themes/nextel";
@import "main";

.ce-colors__nav-item {
  text-transform: uppercase;
}
